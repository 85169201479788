import { Helmet } from 'react-helmet'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { getCMSPageDataByContentType } from 'src/cms/client'
import { slugify } from 'src/utils/slugfy'
import { SportsPageTemplate } from 'src/components/sports/SportsPageTemplate'
import { DepartmentPageTemplate } from 'src/components/department/DepartmentPageTemplate'
import type { PropsCMS } from 'src/components/sports/utils/types'

function SportsPage(props: PropsCMS) {
  const { serverData } = props

  if (serverData === null) {
    return null
  }

  const { cmsData } = serverData

  const seo = cmsData.seo.siteMetadataWithSlug

  return (
    <>
      <Helmet>
        <meta name="keywords" content={seo.keywords} />
        <meta property="og:image" content={seo.ogImage} />
      </Helmet>
      <GatsbySeo
        title={seo.title}
        description={seo.description}
        language="pt-br"
        canonical={`https://www.decathlon.com.br/esportes/${seo.slug}`}
        openGraph={{
          type: 'website',
          url: `https://www.decathlon.com.br/esportes/${seo.slug}`,
          title: seo.title,
          description: seo.description,
        }}
      />
      {cmsData.type === 'department-page' && (
        <DepartmentPageTemplate {...props} />
      )}
      {cmsData.type === 'esportes' && <SportsPageTemplate {...props} />}
    </>
  )
}

export const getServerData = async ({
  params: { slug },
}: {
  query: Record<string, string>
  params: Record<string, string>
}) => {
  const newSlug = slugify(slug, '+')

  const departmentCms = await getCMSPageDataByContentType('department-page', {
    filters: { name: newSlug },
  })

  const sportsCms = await getCMSPageDataByContentType('esportes', {
    filters: { name: newSlug },
  })

  if (!departmentCms && !sportsCms) {
    return {
      status: 301,
      props: null,
      headers: {
        location: `/404/?from=${slug}`,
      },
    }
  }

  return {
    status: 200,
    props: {
      cmsData: departmentCms ?? sportsCms,
    },
    headers: {
      'cache-control': 'no-cache',
    },
  }
}

export default SportsPage
